@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Nunito Sans, system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply bg-layout-scene;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @keyframes asideIn {
    0% {
      @apply w-0;
    }
    100% {
      @apply w-72;
    }
  }

  @keyframes asideOut {
    0% {
      @apply w-72;
    }
    100% {
      @apply w-0;
    }
  }

  @keyframes controlIn {
    0% {
      @apply left-0 opacity-0;
    }
    100% {
      @apply opacity-100 left-72;
    }
  }

  @keyframes controlOut {
    0% {
      @apply opacity-100 left-72;
    }
    100% {
      @apply left-0 opacity-100;
    }
  }

  @keyframes controlFadeOut {
    0% {
      @apply opacity-100 left-72;
    }
    100% {
      @apply left-0 opacity-0;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Toastify reset */
.Toastify__toast {
  min-height: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  padding: unset !important;
  margin: unset !important;
}

.Toastify__toast-body {
  padding: unset !important;
  margin: unset !important;
}

.Toastify__close-button {
  display: none;
}
/* Toastify reset */

.react-datepicker-wrapper {
  width: unset !important;
}
